import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api, { useQuery } from '../../services/api';
import { useHistory } from 'react-router-dom';
import cnpj from '../../utils/cnpj';
import { isValidCPF } from '../../utils/cpf';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import {
  FiUser,
  FiPhone,
  FiCreditCard,
  FiDollarSign,
  FiHome
} from 'react-icons/fi';

import {
  Container,
  Content,
  LoadingWrap,
  AnimationContainer,
  InputGroup,
  Footer
} from './styles';

interface IndicationFormData {
  name: string;
  document: string;
  phone: string;
  value: string;
  product: string;
}

interface InviteData {
  title: string;
  subtitle: string;
  code: string;
  callback: string;
}

const Invite: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { addToast } = useToast();
  const [invite, setInvite] = useState<InviteData>()
  const history = useHistory();
  const keyword = useQuery().get('q')

  const styles = { borderColor: '#111', borderWidth: '1px' }

  const options = [
    { value: '', label: '-- Selecione o Produto --' },
    { value: 'HOME_EQUITY', label: 'Home Equity' },
    { value: 'PESSOAL', label: 'Empréstimo pessoal' },
    { value: 'EMPRESARIAL', label: 'Empréstimo Empresarial' },
    { value: 'CONSIGNADO', label: 'Crédito Consignado' },
  ];

  const handleSubmit = useCallback(
    async (data: IndicationFormData, { reset }) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          document: Yup.string()
            .required('CPF obrigatório')
            .test('CPF_CNPJ', 'Cpf inválido', (value) => isValidCPF(value) || cnpj.isValid(value)),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/subscribers', {
          ...data,
          keyword
        });

        reset()
        history.push('/')

        addToast({
          type: 'success',
          title: 'Dados enviados!',
          description:
            'As informações do indicado foram salvas com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar',
          description: 'Ocorreu um erro ao salvar os dados, tente novamente',
        });
      }
    },
    [addToast, history, keyword],
  );

  useEffect(() => {
    api.get(`invites/${keyword}`).then(res => {
      const { data } = res;
      setInvite(data);
      setLoading(false)
    }).catch(err => {
      setError(true)
    })
  }, [keyword])

  if (loading) {
    return (
      <Container>
        <LoadingWrap>
          {error ? (<p>:( Convite não encontrado</p>) : (
            <>
              <ReactLoading type="spin" color="#fff" height={100} width={100} />
              <p>Obtendo os dados do convite...</p>
            </>
          )
          }
        </LoadingWrap>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>

          <Form
            ref={formRef}
            initialData={{
              name: "",
              document: "",
            }}
            onSubmit={handleSubmit}
          >

            <h1>{!!invite && invite.title}</h1>
            <p>
              {!!invite && invite.subtitle}
            </p>

            <Select
              name="product"
              options={options}
              icon={FiHome}
              defaultValue={{ value: '', label: '-- Selecione o Produto --' }}
            />

            <Input
              containerStyle={styles}
              name="name"
              icon={FiUser}
              placeholder="Como se chama?"
            />

            <Input containerStyle={styles}
              name="document" icon={FiCreditCard} placeholder="CPF ou CNPJ" />

            <InputGroup>
              <Input
                containerStyle={styles}
                name="phone"
                icon={FiPhone}
                type="text"
                placeholder="Telefone"
              />

              <Input
                containerStyle={styles}
                name="value"
                icon={FiDollarSign}
                type="number"
                min="0"
                placeholder="Valor"
              />
            </InputGroup>

            <Button type="submit">Salvar</Button>
          </Form>
        </AnimationContainer>
      </Content>

      <Footer>
        <a target="_blank" rel="noopener noreferrer" href="https://club.finmarc.com.br/signup">
          Ainda não tem conta? <br />
          <b> Cadastre-se </b>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://club.finmarc.com.br/benefits">
          Conheça as vantagens do <br /> Clube de benefícios, <b>Clique aqui!</b>
        </a>
      </Footer>
    </Container>
  );
};

export default Invite;
